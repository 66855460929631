import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

// All props should be optional because each has a default / fallback
type SeoProps = {
    title?: string;
    description?: string;
    pathname?: string;
    children?: JSX.Element;
    tags?: { name: string; content: string }[];
};

const SEO: FC<SeoProps> = ({ title, description, pathname, tags = [], children }) => {
    const seo = {
        title: title || 'Loop3 Studio',
        url: 'https://loop3.studio',
    };

    return (
        <>
            <Helmet title={`${seo.title}`}>
                <meta name="web:url" content={seo.url} />
                <meta name="description" content={description ?? ''} />
                {tags?.map(tag => (
                    <meta name={tag.name} content={tag.content} />
                ))}
            </Helmet>
            {children}
        </>
    );
};

export default SEO;
